/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, Row, Col } from 'react-bootstrap';
import {
  useForm,
  FieldValues,
  SubmitHandler,
  Controller
} from 'react-hook-form';
import { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { useTranslation } from 'react-i18next';

import Button from 'components/base/Button';
import { showAlert } from 'store/alert/alert.thunk';
import versioinService from 'services/version';
import NewDropzone from 'components/base/NewDropzone';

interface CreateVersionProps {
  setOpenDesktopVersion: (show: boolean) => void;
  selectedVersionId?: any;
  refetch?: any;
  setSelectedVersionId?: any;
}

const CreateVersion = ({
  setOpenDesktopVersion,
  selectedVersionId,
  refetch,
  setSelectedVersionId
}: CreateVersionProps) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors }
  } = useForm();

  const dispatch: Dispatch<any> = useDispatch();
  const inputRef = useRef<HTMLInputElement | any>();

  const fetchData = () => {
    if (selectedVersionId === '') return setLoading(false);

    versioinService
      .getVersionById(selectedVersionId)
      .then((res: any) => {
        const computed = {
          name: res?.name,
          lts_status: res?.is_lts,
          mandatory: res?.is_mandatory,
          file_exe: process.env.REACT_APP_CDN_URL + '/' + res.file
        };
        reset(computed);
      })
      .finally(() => setLoading(false));
  };

  const onSubmit: SubmitHandler<FieldValues> = data => {
    if (selectedVersionId !== '') return update(data);
    createData(data);
  };

  const createData = (data: any) => {
    setLoading(true);

    console.log('data', data);

    const createdData = {
      name: data?.name,
      is_lts: data?.lts_status,
      is_mandatory: data?.mandatory,
      file: data?.file_exe ? data.file_exe.split('/').pop() : ''
    };

    versioinService
      .createVersion(createdData)
      .then((res: any) => {
        if (res) {
          dispatch(
            showAlert({
              title: `${t('desktop_version')} ${t('successfully_added')}`,
              type: 'success'
            })
          );
          refetch();
          setOpenDesktopVersion(false);
          setLoading(false);
          setSelectedVersionId('');
        }
      })
      .catch(error => {
        if (error) return setLoading(false);
      });
  };

  const update = (data: any) => {
    setLoading(true);

    const updateData = {
      name: data?.name,
      is_lts: data?.lts_status,
      is_mandatory: data?.mandatory,
      file:
        data.file_exe.split('/').pop() !== 'undefined'
          ? data.file_exe.split('/').pop()
          : '',
      id: selectedVersionId
    };

    versioinService.updateVersion(selectedVersionId, updateData).finally(() => {
      refetch();
      setLoading(false);
      setOpenDesktopVersion(false);
      setSelectedVersionId('');
    });
  };

  useEffect(() => {
    if (selectedVersionId !== '') {
      fetchData();
    }
  }, [selectedVersionId]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
      setTimeout(() => {
        inputRef?.current?.select();
      }, 500);
    }
  }, [inputRef.current]);

  return (
    <>
      <Form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
      >
        <Row className="mb-3">
          <Col>
            <Controller
              name={`name`}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Form.Floating className="md-4">
                  <Form.Control
                    {...field}
                    ref={inputRef}
                    type="text"
                    placeholder={t('name_version')}
                    onFocus={e => e.target.select()}
                    isInvalid={!!errors[`name`]}
                  />
                  <label htmlFor="floatingInputCustom">
                    {t('name_version')}
                  </label>
                  {errors.name && (
                    <Form.Control.Feedback type="invalid">
                      {t('required_field')}
                    </Form.Control.Feedback>
                  )}
                </Form.Floating>
              )}
            />
          </Col>
        </Row>

        <Form.Group className="mb-3 d-flex gap-3">
          <Controller
            name="lts_status"
            control={control}
            defaultValue={false}
            render={({ field }) => (
              <Form.Check
                {...field}
                type="switch"
                label={t('latest_version')}
                checked={field.value}
                onChange={e => field.onChange(e.target.checked)}
              />
            )}
          />
          <Controller
            name="mandatory"
            control={control}
            defaultValue={false}
            render={({ field }) => (
              <Form.Check
                {...field}
                type="switch"
                label={t('required')}
                checked={field.value}
                onChange={e => field.onChange(e.target.checked)}
              />
            )}
          />
        </Form.Group>

        {selectedVersionId === '' && (
          <Row className="mb-3">
            <Col>
              <Controller
                name="file_exe"
                control={control}
                defaultValue=""
                render={({ field }) => <NewDropzone {...field} />}
              />
            </Col>
          </Row>
        )}

        <Button
          style={{ height: '48px', marginTop: 'auto' }}
          variant="primary"
          type="submit"
          loading={loading}
        >
          {t('add')}
        </Button>
      </Form>
    </>
  );
};

export default CreateVersion;
