/* eslint-disable @typescript-eslint/no-explicit-any */
import { useRef, useState } from 'react';
import FeatherIcon from 'feather-icons-react';
import { Spinner } from 'react-bootstrap';
import ImageViewer from 'react-simple-image-viewer';
import { useTranslation } from 'react-i18next';

import uploadService from 'services/fileService';
import './style.scss';

interface ImageUploadProps {
  value?: any;
  onChange?: any;
  className?: any;
}

const ImageUpload = ({ value, onChange, className }: ImageUploadProps) => {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement | any>();
  const [previewVisible, setPreviewVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB

  const imageClickHandler = () => {
    setPreviewVisible(true);
  };

  const inputChangeHandler = (e: any) => {
    setLoading(true);
    const file = e.target.files[0];

    if (file.size > MAX_FILE_SIZE) {
      setLoading(false);
      setError('File size exceeds 5MB limit');
      return;
    }

    const data = new FormData();
    data.append('file', file);

    uploadService
      .uploadImage(data)
      .then((res: any) => {
        onChange(process.env.REACT_APP_CDN_URL + '/' + res.filename);
        setError(null);
      })
      .finally(() => setLoading(false));
  };

  const deleteImage = () => {
    onChange(null);
  };

  const closeButtonHandler = (e: any) => {
    e.stopPropagation();
    deleteImage();
  };

  return (
    <div className={`Gallery ${className}`}>
      {value && (
        <div className="block" onClick={() => imageClickHandler()}>
          <button
            className="close-btn"
            type="button"
            onClick={e => {
              closeButtonHandler(e);
            }}
          >
            <FeatherIcon icon="x-circle" />
          </button>
          <img src={value} alt="" />
        </div>
      )}

      {!value && (
        <div
          className="add-block block"
          onClick={() => inputRef.current.click()}
        >
          <div className="add-icon">
            {!loading ? (
              <>
                <FeatherIcon icon="plus" style={{ color: '#8200BF' }} />
                <p className="mb-0">{t('download')}</p>
              </>
            ) : (
              <Spinner animation="border" style={{ color: '#8200BF' }} />
            )}
          </div>

          <input
            type="file"
            className="hidden"
            ref={inputRef}
            onChange={inputChangeHandler}
          />
        </div>
      )}

      {error && <p className="error-message">{error}</p>}

      {previewVisible && (
        <ImageViewer
          src={[value]}
          currentIndex={0}
          disableScroll={true}
          closeOnClickOutside={true}
          onClose={() => setPreviewVisible(false)}
        />
      )}
    </div>
  );
};

export default ImageUpload;
