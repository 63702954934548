/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeEvent, useState, useMemo } from 'react';
import { Col, Row, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { ColumnDef } from '@tanstack/react-table';
import FeatherIcon from 'feather-icons-react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

import HoldingTable from './HoldingTable';
import SearchBox from 'components/common/SearchBox';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import useAdvanceTable from 'hooks/useAdvanceTable';
import { holdingBreadcrumbItems, HoldingType } from 'data/holding';
import Button from 'components/base/Button';
import CreateHolding from './CreateHolding';
import legalEntityService from 'services/legalEntity';
import Lightbox from 'components/base/LightBox';
import useLightbox from 'hooks/useLightbox';

const Holding = () => {
  const { t, i18n } = useTranslation();

  const [openHolding, setOpenHolding] = useState(false);
  const [selectedHoldingId, setSelectedHoldingId] = useState('');

  //Get Legal entity
  const { data, refetch } = useQuery(['GET_LEGAL_ENTITY'], () => {
    const res = legalEntityService
      .getList({ offset: 0, limit: 100 })
      .then((res: any) => res?.legal_entities);
    return res;
  });

  const legalData: HoldingType[] = useMemo(() => {
    return (
      data?.map((items: any) => {
        return {
          name: items?.name,
          address: items?.address,
          phone_number: items?.phone_number,
          activity: items?.activity_kind?.name?.[i18n?.language],
          country: items?.country?.name?.[i18n?.language],
          currency: items?.currency?.name?.[i18n?.language],
          area: items?.district?.name?.[i18n?.language],
          region: items?.region?.name?.[i18n?.language],
          access: items?.legal_entity_access?.name?.[i18n?.language],
          entity_status: items?.legal_entity_status?.name?.[i18n?.language],
          logo_url: items?.logo_url
            ? `${process.env.REACT_APP_CDN_URL}/${items.logo_url}`
            : '',
          guid: items?.id
        };
      }) ?? []
    );
  }, [data, t, i18n?.language]);

  const handleDelete = (id: any) => {
    legalEntityService.deleteLegalEntity(id).finally(() => {
      refetch();
      setSelectedHoldingId('');
    });
  };

  const genderTableColumns: ColumnDef<HoldingType>[] = [
    {
      accessorKey: 'name',
      header: t('name_holding'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'address',
      header: t('address'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'phone_number',
      header: t('phone_number'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'activity',
      header: t('type_activity'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'country',
      header: t('country'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'currency',
      header: t('currency'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'area',
      header: t('area'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'region',
      header: t('region'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'access',
      header: t('legal_entity_accesses'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'photo',
      header: t('photo'),
      cell: rowData => {
        const [attachments] = useState([rowData?.row?.original.logo_url]);
        const { lightboxProps, openLightbox } = useLightbox(attachments);

        return (
          <>
            {rowData?.row?.original.logo_url && (
              <div>
                <Lightbox {...lightboxProps} />

                {attachments.map((img, index) => (
                  <img
                    key={img}
                    style={{
                      width: '35px',
                      height: '35px',
                      border: '1px solid #8200BF',
                      borderRadius: '100%',
                      cursor: 'pointer'
                    }}
                    src={img}
                    alt=""
                    onClick={() => openLightbox(index + 1)}
                  />
                ))}
              </div>
            )}
          </>
        );
      },

      meta: {
        cellProps: { className: 'text-900 text-center' }
      }
    },
    {
      accessorKey: 'entity_status',
      header: t('legal_entity_status'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      id: 'action',
      cell: rowData => (
        <div className="d-flex justify-content-end gap-3">
          <Link to={`#`}>
            <FeatherIcon
              icon="edit-2"
              className="cursor-pointer"
              size={20}
              onClick={() => {
                setOpenHolding(true);
                setSelectedHoldingId(rowData?.row?.original?.guid);
              }}
            />
          </Link>
          <Link to={`#`}>
            <FeatherIcon
              icon="trash-2"
              className="cursor-pointer text-danger"
              size={20}
              onClick={() => handleDelete(rowData?.row?.original?.guid)}
            />
          </Link>
        </div>
      ),
      meta: {
        headerProps: { style: { width: '7%' } },
        cellProps: { className: 'text-end' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: legalData,
    columns: genderTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };
  return (
    <div>
      <PageBreadcrumb items={holdingBreadcrumbItems} />

      <div className="mb-9">
        <h2 className="mb-5">{t('holding')}</h2>

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <Row className="d-flex justify-content-between">
              <Col xs="auto">
                <SearchBox
                  placeholder={t('search')}
                  onChange={handleSearchInputChange}
                />
              </Col>

              <Col xs="auto">
                <Button
                  className="mt-3"
                  variant="primary"
                  onClick={() => setOpenHolding(true)}
                >
                  <FontAwesomeIcon icon={faPlus} className="me-2" />
                  {t('add')}
                </Button>
              </Col>
            </Row>
          </div>
          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-300 position-relative top-1">
            <HoldingTable />
          </div>

          <Modal
            show={openHolding}
            onHide={() => {
              setOpenHolding(false);
              setSelectedHoldingId('');
            }}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                {t('new')}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <CreateHolding
                setOpenHolding={setOpenHolding}
                selectedHoldingId={selectedHoldingId}
                refetch={refetch}
              />
            </Modal.Body>
          </Modal>
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export default Holding;
