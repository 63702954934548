/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useMemo, useEffect } from 'react';
import { Col, Row, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { ColumnDef } from '@tanstack/react-table';
import FeatherIcon from 'feather-icons-react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

import SearchBox from 'components/common/SearchBox';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import useAdvanceTable from 'hooks/useAdvanceTable';
import Button from 'components/base/Button';
import CreateNomenclature from './CreateNomenclature';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableCPagination from 'components/base/AdvanceTableCPagination';
import { pageToOffset } from 'utils/pageToOffset';

import {
  nomenclatureBreadcrumbItems,
  NomenclatureType
} from 'data/nomenclature';
import nomenclatureService from 'services/nomenclature';

const Nomenclature = () => {
  const { t, i18n } = useTranslation();

  const [openNomenclature, setOpenNomenclature] = useState(false);
  const [selectedNomenclatureId, setSelectedNomenclatureId] = useState('');
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState('');

  //Get Nomenclature
  const { data, refetch } = useQuery(
    ['GET_NOMENCLATURE', currentPage, searchValue],
    () => {
      const res = nomenclatureService
        .getList({
          limit: 30,
          offset: pageToOffset(currentPage, 30),
          name: searchValue
        })
        .then((res: any) => res);
      return res;
    },
    { enabled: !!currentPage || !!searchValue }
  );

  useEffect(() => {
    if (data) {
      setPageCount(Math.ceil(data?.count / 30));
    }
  }, [data]);

  const nomenclatureData: NomenclatureType[] = useMemo(() => {
    return (
      data?.nomenclatures?.map((items: any) => {
        return {
          ...items,
          category_id: items?.category?.name?.[i18n?.language],
          product_kind_id: items?.productKind?.name?.[i18n?.language],
          country_id: items?.country?.name?.[i18n?.language],
          legal_entity_id: items?.legal_entity?.name,
          measure_unit_id: items?.measure_unit?.name?.[i18n?.language],
          measure_unit_kind_id:
            items?.measure_unit_kind?.name?.[i18n?.language],
          product_status_id: items?.product_status?.name?.[i18n?.language],
          custom_brand_id: items?.custom_brand?.name?.[i18n?.language],
          guid: items?.id
        };
      }) ?? []
    );
  }, [data, t, i18n?.language]);

  const handleDelete = (id: any) => {
    nomenclatureService.deleteNomenclature(id).finally(() => {
      refetch();
      setSelectedNomenclatureId('');
    });
  };

  const nomenclatureTableColumns: ColumnDef<NomenclatureType>[] = [
    {
      id: 'action',
      cell: rowData => (
        <div className="d-flex gap-3">
          <Link to={`#`}>
            <FeatherIcon
              icon="edit-2"
              className="cursor-pointer"
              size={20}
              onClick={() => {
                setOpenNomenclature(true);
                setSelectedNomenclatureId(rowData?.row?.original?.guid);
              }}
            />
          </Link>
          <Link to={`#`}>
            <FeatherIcon
              icon="trash-2"
              className="cursor-pointer text-danger"
              size={20}
              onClick={() => handleDelete(rowData?.row?.original?.guid)}
            />
          </Link>
        </div>
      ),
      meta: {
        headerProps: { style: { width: '7%' } },
        cellProps: { className: 'text-start' }
      }
    },
    {
      accessorKey: 'name',
      header: t('full_name'),
      meta: {
        headerProps: { className: 'text-nowrap' },
        cellProps: { className: 'text-900 white-space-nowrap' }
      }
    },
    {
      accessorKey: 'short_name',
      header: t('short_name'),
      meta: {
        headerProps: { className: 'text-nowrap' },
        cellProps: { className: 'text-900 white-space-nowrap' }
      }
    },

    {
      accessorKey: 'barcode',
      header: t('barcode'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'custom_brand_id',
      header: t('brands'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'category_id',
      header: t('category'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'country_id',
      header: t('country'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'legal_entity_id',
      header: t('holding'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'measure_unit_id',
      header: t('measure_unit'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'measure_unit_kind_id',
      header: t('measure_unit_type'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'package_quantity',
      header: t('package_quantity'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'part_number',
      header: t('party_number'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'product_kind_id',
      header: t('product_type'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'product_status_id',
      header: t('product_status'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'ikpu',
      header: t('code_ikpu'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'synonyms',
      header: t('synonyms'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'description',
      header: t('description'),
      meta: {
        headerProps: {
          style: { width: '100%', minWidth: 1500 }
        },
        cellProps: { className: 'text-900' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: nomenclatureData,
    columns: nomenclatureTableColumns,
    pageSize: 30,
    pagination: true,
    sortable: true,
    selection: false
  });

  return (
    <div>
      <PageBreadcrumb items={nomenclatureBreadcrumbItems} />

      <div className="mb-9">
        <h2 className="mb-5">{t('nomenclature')}</h2>

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <Row className="d-flex justify-content-between">
              <Col xs="auto">
                <SearchBox
                  value={searchValue}
                  placeholder={t('search')}
                  onChange={e => setSearchValue(e?.target?.value)}
                />
              </Col>

              <Col xs="auto">
                <Button
                  className="mt-3"
                  variant="primary"
                  onClick={() => setOpenNomenclature(true)}
                >
                  <FontAwesomeIcon icon={faPlus} className="me-2" />
                  {t('add')}
                </Button>
              </Col>
            </Row>
          </div>
          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-300 position-relative top-1">
            <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
            <AdvanceTableCPagination
              count={pageCount}
              page={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </div>

          <Modal
            show={openNomenclature}
            onHide={() => {
              setOpenNomenclature(false);
              setSelectedNomenclatureId('');
            }}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                {t('new')}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <CreateNomenclature
                setOpenNomenclature={setOpenNomenclature}
                selectedNomenclatureId={selectedNomenclatureId}
                refetch={refetch}
              />
            </Modal.Body>
          </Modal>
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export default Nomenclature;
